import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getResearchs } from "../actions/research";
import { allPapers, acceptedPapers, rejectedPapers } from "../common/sections";
const Statistics = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getResearchs());
  }, []);
  const { researchs, lang } = useSelector((state) => state.research);
  const rejected = researchs.filter(
    (research) => research.status3 === "rejected"
  );
  const accepted = researchs.filter(
    (research) => research.status3 === "accepted"
  );

  return (
    <section
      id="statist"
      className="fun-fact-section style-two"
      style={{ backgroundImage: "url(images/background/9.jpg)" }}
    >
      <div className="auto-container">
        <div className="fact-counter">
          <div className="row clearfix">
            <div
              className="counter-column col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-delay="1200ms"
            >
              <div className="count-box">
                <span className="icon icon_book_alt"></span>
                <span className="count-text" data-speed="3000" data-stop="38">
                  {researchs.length}
                </span>
                <h4 className="counter-title">{allPapers[lang]}</h4>
              </div>
            </div>
            <div
              className="counter-column col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-delay="800ms"
            >
              <div className="count-box">
                <span className="icon icon_like"></span>
                <span className="count-text" data-speed="3000" data-stop="54">
                  {accepted.length}
                </span>
                <h4 className="counter-title">{acceptedPapers[lang]}</h4>
              </div>
            </div>
            <div
              className="counter-column col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-delay="400ms"
            >
              <div className="count-box">
                <span className="icon icon_dislike"></span>
                <span className="count-text" data-speed="3000" data-stop="62">
                  {rejected.length}
                </span>
                <h4 className="counter-title">{rejectedPapers[lang]}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Statistics;
