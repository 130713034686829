import React from "react";
import { useSelector } from "react-redux";
import {
  navlink7,
  navlink4,
  Date1,
  Date2,
  Date3,
  Date4,
  Date5,
  Date6,
  Date7,
  Date8,
} from "../common/sections";
const Plan = () => {
  const { lang } = useSelector((state) => state.research);
  return (
    <section id="plan" className="schedule-section">
      <div className="anim-icons">
        <span className="icon icon-circle-4 wow zoomIn"></span>
        <span className="icon icon-circle-3 wow zoomIn"></span>
      </div>

      <div className="auto-container">
        <div className="sec-title text-center">
          <span className="newtitle">{navlink4[lang]}</span>
          <h2>{navlink7[lang]}</h2>
        </div>

        <div className="schedule-tabs tabs-box">
          <div className="tabs-content">
            <div className="tab active-tab" id="tab-1">
              <div className="schedule-timeline">
                <div className="schedule-block">
                  <div className="inner-box">
                    <div className="inner">
                      <div className="date">
                        {lang === "ar" ? "05-06" : "05-6"}
                        <br />
                        2021
                      </div>

                      <h5 className="text-center">{Date1[lang]}</h5>
                    </div>
                  </div>
                </div>

                <div className="schedule-block even">
                  <div className="inner-box">
                    <div className="inner">
                      <div className="date">
                        {lang === "ar" ? "06-03" : "03-06"}
                        <br />
                        2021
                      </div>
                      <h5 className="text-center">{Date2[lang]}</h5>
                    </div>
                  </div>
                </div>

                <div className="schedule-block">
                  <div className="inner-box">
                    <div className="inner">
                      <div className="date">
                        {lang === "ar" ? "06-10" : "10-06"}
                        <br />
                        2021
                      </div>
                      <h5 className="text-center">{Date3[lang]}</h5>
                    </div>
                  </div>
                </div>

                <div className="schedule-block even">
                  <div className="inner-box">
                    <div className="inner">
                      <div className="date">
                        {lang === "ar" ? "09-02" : "02-09"}
                        <br />
                        2021
                      </div>
                      <h5 className="text-center">{Date4[lang]}</h5>
                    </div>
                  </div>
                </div>

                <div className="schedule-block">
                  <div className="inner-box">
                    <div className="inner">
                      <div className="date">
                        {lang === "ar" ? "10-03" : "03-10"}
                        <br />
                        2021
                      </div>
                      <h5 className="text-center">{Date5[lang]} </h5>
                    </div>
                  </div>
                </div>

                <div className="schedule-block even">
                  <div className="inner-box">
                    <div className="inner">
                      <div className="date">
                        {lang === "ar" ? "10-25" : "25-10"}
                        <br />
                        2021
                      </div>
                      <h5 className="text-center">{Date6[lang]}</h5>
                    </div>
                  </div>
                </div>

                <div className="schedule-block">
                  <div className="inner-box">
                    <div className="inner">
                      <div className="date">
                        {lang === "ar" ? "11-11" : "11-11"}
                        <br />
                        2021
                      </div>
                      <h5 className="text-center">{Date7[lang]}</h5>
                    </div>
                  </div>
                </div>
                <div className="schedule-block even">
                  <div className="inner-box">
                    <div className="inner">
                      <div className="date">
                        {lang === "ar" ? "11-12" : "12-11"}
                        <br />
                        2021
                      </div>
                      <h5 className="text-center">{Date8[lang]} </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Plan;
