import React from "react";
import { useSelector } from "react-redux";
import { comission } from "../common/sections";
const Commitee = () => {
  const { lang } = useSelector((state) => state.research);
  return (
    <section id="commitee" className="speakers-section-two">
      <div className="anim-icons">
        <span className="icon icon-circle-4 wow zoomIn"></span>
        <span className="icon icon-circle-3 wow zoomIn"></span>
      </div>

      <div className="auto-container">
        <div className="sec-title text-center">
          <h2 className="title" style={{ fontSize: "3rem" }}>
            {" "}
            {comission[lang]}
          </h2>
        </div>

        <div className="row">
          <div className="speaker-block-two col-lg-3 col-md-6 col-sm-12 wow fadeInUp">
            <div className="inner-box">
              <div className="info-box">
                <h4 className="name">
                  <a href="speakers-detail.html">د. مفتاح محمد المرابط </a>
                </h4>
                <span className="designation">الرئيس الفخري للمؤتمر</span>
              </div>
              <div className="image-box">
                <figure className="image">
                  <a href="speakers-detail.html">
                    <img src="images/resource/speaker-1.jpg" alt="" />
                  </a>
                </figure>
              </div>
              {/* <div className="social-box">
                <ul className="social-links social-icon-colored">
                  <li>
                    <a href="#">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-pinterest"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-dribbble"></i>
                    </a>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>

          <div className="speaker-block-two col-lg-3 col-md-6 col-sm-12 wow fadeInUp">
            <div className="inner-box">
              <div className="info-box">
                <h4 className="name">
                  <a href="speakers-detail.html">أ.د عماد محمد أبوعجيلة</a>
                </h4>
                <span className="designation">رئيس المؤتمر</span>
              </div>
              <div className="image-box">
                <figure className="image">
                  <a href="speakers-detail.html">
                    <img src="images/resource/speaker-2.jpg" alt="" />
                  </a>
                </figure>
              </div>
              {/* <div className="social-box">
                <ul className="social-links social-icon-colored">
                  <li>
                    <a href="#">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-pinterest"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-dribbble"></i>
                    </a>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>

          <div className="speaker-block-two col-lg-3 col-md-6 col-sm-12 wow fadeInUp">
            <div className="inner-box">
              <div className="info-box">
                <h4 className="name">
                  <a href="speakers-detail.html">د. إسماعيل إبراهيم أحمد </a>
                </h4>
                <span className="designation">نائب رئيس المؤتمر</span>
              </div>
              <div className="image-box">
                <figure className="image">
                  <a href="speakers-detail.html">
                    <img src="images/resource/speaker-4.jpg" alt="" />
                  </a>
                </figure>
              </div>
              {/* <div className="social-box">
                <ul className="social-links social-icon-colored">
                  <li>
                    <a href="#">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-pinterest"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-dribbble"></i>
                    </a>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>

          <div className="speaker-block-two col-lg-3 col-md-6 col-sm-12 wow fadeInUp">
            <div className="inner-box">
              <div className="info-box">
                <h4 className="name">
                  <a href="speakers-detail.html">أ.د مختار الطاهر كرفاع</a>
                </h4>
                <span className="designation">رئيس اللجنة التحضيرية </span>
              </div>
              <div className="image-box">
                <figure className="image">
                  <a href="speakers-detail.html">
                    <img src="images/resource/speaker-3.jpg" alt="" />
                  </a>
                </figure>
              </div>
              {/* <div className="social-box">
                <ul className="social-links social-icon-colored">
                  <li>
                    <a href="#">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-pinterest"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-dribbble"></i>
                    </a>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="speaker-block-two col-lg-3 col-md-6 col-sm-12 wow fadeInUp">
            <div className="inner-box">
              <div className="info-box">
                <h4 className="name">
                  <a href="speakers-detail.html">د. زريمق مولود زريمق </a>
                </h4>
                <span className="designation">رئيس اللجنة العلمية </span>
              </div>
              <div className="image-box">
                <figure className="image">
                  <a href="speakers-detail.html">
                    <img src="images/resource/speaker-1.jpg" alt="" />
                  </a>
                </figure>
              </div>
              {/* <div className="social-box">
                <ul className="social-links social-icon-colored">
                  <li>
                    <a href="#">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-pinterest"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-dribbble"></i>
                    </a>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>

          <div className="speaker-block-two col-lg-3 col-md-6 col-sm-12 wow fadeInUp">
            <div className="inner-box">
              <div className="info-box">
                <h4 className="name">
                  <a href="speakers-detail.html">أ. محمود جمعة البشتي</a>
                </h4>
                <span className="designation">رئيس اللجنة التقنية</span>
              </div>
              <div className="image-box">
                <figure className="image">
                  <a href="speakers-detail.html">
                    <img src="images/resource/speaker-2.jpg" alt="" />
                  </a>
                </figure>
              </div>
              {/* <div className="social-box">
                <ul className="social-links social-icon-colored">
                  <li>
                    <a href="#">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-pinterest"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-dribbble"></i>
                    </a>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>

          <div className="speaker-block-two col-lg-3 col-md-6 col-sm-12 wow fadeInUp">
            <div className="inner-box">
              <div className="info-box">
                <h4 className="name">
                  <a href="speakers-detail.html">أ. فاطمة نصر بن ناجى </a>
                </h4>
                <span className="designation">رئيس اللجنة الإعلامية</span>
              </div>
              <div className="image-box">
                <figure className="image">
                  <a href="speakers-detail.html">
                    <img src="images/resource/speaker-4.jpg" alt="" />
                  </a>
                </figure>
              </div>
              {/* <div className="social-box">
                <ul className="social-links social-icon-colored">
                  <li>
                    <a href="#">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-pinterest"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-dribbble"></i>
                    </a>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>

          <div className="speaker-block-two col-lg-3 col-md-6 col-sm-12 wow fadeInUp">
            <div className="inner-box">
              <div className="info-box">
                <h4 className="name">
                  <a href="speakers-detail.html">عبد الناصر على المرابط</a>
                </h4>
                <span className="designation">رئيس لجنة الاستقبال والخدمات </span>
              </div>
              <div className="image-box">
                <figure className="image">
                  <a href="speakers-detail.html">
                    <img src="images/resource/speaker-3.jpg" alt="" />
                  </a>
                </figure>
              </div>
              {/* <div className="social-box">
                <ul className="social-links social-icon-colored">
                  <li>
                    <a href="#">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-pinterest"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-dribbble"></i>
                    </a>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Commitee;
