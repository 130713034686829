export const navlink1 = {
  ar: "الرئيسية ",
  en: "Home",
  fr: "Page d'accueil",
};
export const navlink2 = {
  ar: "الجهات المنظمة ",
  en: "Organizers",
  fr: "Les organisateurs",
};
export const navlink3 = {
  ar: "المتحدثون ",
  en: "Speakers",
  fr: "Haut-parleurs",
};

export const navlink4 = {
  ar: "عن المؤتمر ",
  en: "About",
  fr: "À propos",
};
export const navlink4_4 = {
  ar: "كيفية التسجيل ",
  en: "How To Regester",
  fr: "Comment s'inscrire",
};
export const navlink4_5 = {
  ar: "العدادات",
  en: "Conference statistics",
  fr: "Statistiques de la conférence",
};
export const navlink5 = {
  ar: "رعاة المؤتمر ",
  en: "Sponsors",
  fr: "Les sponsors",
};
export const navlink6 = {
  ar: "اللجان المنظمة ",
  en: "Supervisory committees",
  fr: "Comités de surveillance",
};
export const navlink7 = {
  ar: "مواعيد مهمة",
  en: "Important Dates",
  fr: "Rendez-vous importants",
};

export const head1 = {
  ar: "المؤتمر العلمي الدولي حول ",
  en: "The first Mediterranean conference marked with a",
  fr: "Le Premier Colloque Méditerranéen Sur le Thème",
};

export const head2 = {
  ar: "دور الجامعات في تعزيز الانتماء الوطني",
  en:
    "The role of the private sector in achieving the goals of sustainable development",
  fr:
    "Le rôle du secteur privé dans la réalisation des objectifs du développement durable",
};

export const head3 = {
  ar: "جامعة الزاوية ",
  en: "Tunisia",
  fr: "Tunisia",
};

export const head4 = {
  ar: "تقديم ",
  en: "Foreword",
  fr: "AVANT-PROPOS ",
};

export const Timing = {
  ar: "الوقت ",
  en: "Timing",
  fr: "Timing",
};
export const Place = {
  ar: "المكان ",
  en: "Place",
  fr: "Endroit",
};
export const PublicMadels = {
  ar: "للتعرف و لتحميل قالب النشر",
  en: "To download the publication template",
  fr: "Pour télécharger le modèle de publication",
};
export const folded = {
  ar: "مطوية المؤتمر",
  en: "Conference folded",
  fr: "Conférence pliée",
};

export const head5 = {
  ar: "اهلا بكم في المؤتمر الدولي حول دور الجامعات في تعزيز الانتماء الوطني",
  en: "Welcome To The first Mediterranean conference 2021",
  fr: "Welcome To The first Mediterranean conference 2021",
};
export const Notes = {
  ar: `
  <li>أن يكون البحث ضمن محاور المؤتمر المعلن عنها. 
  </li>
  <li>يجب ألا يتجاوز الملخص صفحة واحدة مبينًا المشكلة والأهداف والمنهج المستخدم ووسيلة جمع البيانات.
  </li>
  <li>يمكن المشاركة بصفة حضورية او عن بُعد.
  </li>
 <li>يرفق مع الملخص السيرة الذاتية للباحث.
  </li>
  <li>لا تقبل البحوث التي تمت المشاركة بها في مؤتمرات سابقة، أو قُبلت للنشر في مجلات علمية محكمة.
  </li>
  <li>أن يلتزم الباحث بشروط النشر في المجلة الجامعة التي تصدر عن مركز البحوث والاستشارات العلمية بجامعة الزاوية، والموضحة في شروط النشر.
  </li>
  <li>رسوم المشاركة: من خارج ليبيا (150 دولار أمريكي) - من داخل ليبيا (150 دينار ليبي).
 </li>
  <li>سيتم نشر الأبحاث المقبولة بالمؤتمر الكترونيًا في عدد خاص بالمجلة الجامعة.
  </li>
  <li>يتكفل المؤتمر بإقامة الباحثين الذين قُبلت أبحاثهم طيلة فترة انعقاد المؤتمر(تشمل الإقامة لباحث واحد في حالة البحوث المشتركة).
  </li>
  `,
  en: `
  <li>Entries are only accepted by registering through the website.
  </li>
  <li> Research inputs are accepted in an attending and remote capacity.
  </li>
  Single, double and triple entries are accepted.
  </li>
  <li>The organizing committee shall bear the accommodation and subsistence costs for only one participant in the research intervention.
  </li>
  <li>We do not bear the costs of transportation, travel and health insurance.
  </li>
  `,
  fr: `
  <li>Les inscriptions ne sont acceptées qu'en s'inscrivant sur le site Web.
  </li>
  <li> Les apports de recherche sont acceptés en tant que participant et à distance.
  </li>
  Les inscriptions simples, doubles et triples sont acceptées.
  </li>
  <li>Le comité d'organisation prend en charge les frais d'hébergement et de séjour d'un seul participant à l'intervention de recherche.
  </li>
  <li>Nous ne prenons pas en charge les frais de transport, de voyage et d'assurance maladie.
  </li>
  `,
};

export const about1 = {
  ar: `ُيعد تعزيز الانتماء الوطني موضوعا بالغ الدقة، وقضية محورية بالغة الأهمية، إذ لا جدال في أهميته كأساس لقيام واستمرارية المجتمعات والدول الحديثة في عالمناالمعاصر،كماأنه يضفي على المجتمعات والدول خصائصها المتميزة عن غيرها حضاريا، وثقافيا واجتماعيا، وسياسيا، فهو بمثابة الشفرة أو البصمة الحضارية التي تشكل كينونة الدول والمجتمعات الحديثة، ومرجعيتها الحضارية، وروحها العامة، وذاكرتها التاريخية ولغتها وخصائصها وسماتها، وروابط الولاء والانتماء
  بين عناصرها ومكوناتها الأساسية.
  <p>     إنّ غرس مفهوم وقيمة الانتماء الوطني يُعد ركيزة أساسية في صون وجدان الأمة وحمايتها من الانزواء أو الاندثار أمام الهويات الأخرى، فحينما نتحدث عن هذا المفهوم، فإننا نتحدث عن أرض، ووطن، ولغة، وانتماء. </P>
  <p>     وتُعتبر الجامعات من أهم المؤسسات الوطنية التي تهدف لأداء مهمة جوهرية ومحورية لتكوين وزرع مجموعة من القيم الوطنية والسياسية، وإنّ اتصالها بالمجتمع أمرًا ضروريًا تقتضيه المتغيرات العالمية. ومن هذا المنطلق جاءت فكرة المؤتمر حول دور الجامعات في تعزيز الانتماء الوطني.</p>`,
  en: ``,

  fr: ``,
};

export const about2 = {
  ar: `للتعرف و لتحميل قالب النشر  :`,
  en: "Journal publishing rules and conditions",
  fr: `Règles et conditions de publication de revues`,
};
export const ContactUsTitle = {
  ar: `للتواصل`,
  en: "To communicate ",
  fr: `Communiquer`,
};

export const contactUs = {
  ar: `
<h4>
  <span class="icon far fa-calendar"></span> نوفمبر 10-11, 2021
</h4>
<div class="text"> المراسلات : </div>
<ul class="info-list">
  <li>
    <span class="icon icon_profile"></span> اللجنة العلمية 
  </li>
  <li>
    <span class="icon icon_phone"></span>
    <a href="tel:00218 92 8912669 ">00218928912669 </a>
  </li>
  
  <li>
    <span class="icon icon_profile"></span> اللجنة العلمية
  </li>
  <li>
    <span class="icon icon_phone"></span>
    <a href="tel:00218 94 4030763">00218944030763</a>
  </li>
  
  <li>
  <span class="icon icon_mail"></span>
  <a href="mailto:iscnb@zu.edu.ly">iscnb@zu.edu.ly
  </a>
</li>

</ul>
`,
  en: `
`,
  fr: ``,
};
export const about4 = {
  ar: "قواعد وشروط النشر",
  en: "Journal publishing rules and conditions",
  fr: `Règles et conditions de publication de revues`,
};

export const about5 = {
  ar: `<li>البحوث المقدمة إلى المجلة أصيلة أو مبتكرة ولم يسبق أن نشرت أو قدمت للنشر في مجلة أخرى، وغير مستلة من أي أطروحة علمية.</li>
  <li>تحمل الصفحة الأولى من البحث عنوان البحث، واسم الباحث ثلاثياً، ودرجته العلمية والعنوان الذي تتم عليه المراسلة في صفحة مستقلة، ترفق مع البحث وتعاد كتابة عنوان البحث واسم الباحث والمؤسسة العلمية التي يتبعها على الصفحة الأولى منه، ويفضل ألا تزيد صفحات البحث عن عشرين صفحة بما فيها صفحات الرسوم والأشكال والجداول وقائمة المراجع.</li>
  <li>يجب أن يرفق بكل بحث أو دراسة ملخص قصير لا يتجاوز صفحة واحدة باللغة التي كتب بها البحث، على أن يرفق بكل بحث كتب باللغة الإنجليزية ملخص باللغة العربية وآخر باللغة الإنجليزية.</li>
  <li>يقدم البحث مطبوعاً على جهاز الحاسوب وعلى وجه واحد من ورق  مقاس A4 (21×29سم )مرفق بنسختين مع ترك مسافة 3سم من جهات الصفحات الأربع ، ويجب أن ترقم الصفحات ترقيماً متسلسلاً بما في ذلك الجداول والأشكال والصور واللوحات وقائمة المراجع .</li>
  <li>في التوثيق تتم الإشارة إلى مصادر البحث بأرقام مسلسلة في المتن وفقاً لترتيب ورودها فيه ، وتلحق هوامش كل صفحات البحث في نهاية البحث تبعاً لتسلسلها ، وينبغي أن يلتزم الباحث بذكر معلومات التوثيق على النحو التالي :
      <ul>
          <li>في حالة الكتب : يذكر اسم المؤلف كاملاً ، ثم عنوان الكتاب ، بلد النشر ، دار النشر ، سنة النشر ، ورقم الصفحة أو الصفحات .</li>
          <li>في حالة المقالات المنشورة في دوريات متخصصة ، يذكر اسم الدورية ، ثم رقم المجلد ورقم العدد ، ثم تاريخ النشر ورقم الصفحة أو الصفحات التي يشغلها المقال .</li>
          <li>إذا كان المرجع جزءاً من كتاب لغير مؤلفه ، يذكر اسم صاحب البحث أولاً ، ثم عنوان البحث بين علامتي التنصيص ، ثم كلمة (في) ثم اسم صاحب الكتاب أو المرجع ، ثم تستوفى بقية عناصر التوثيق على النحو المشار إليها في الفقرة السابقة .</li>
      </ul>
  </li>
  <li>تقوم المجلة بإبلاغ الباحث بوصول بحثه إلى هيئة التحرير ، وتبلغه باعتذارها إذا لم يتقرر نشره فيها ، ولا تلتزم المجلة برد الأبحاث التي لا يتم نشرها إلى أصحابها .</li>
  <li>لا يحق للباحث إعادة نشر بحثه في أية مجلة علمية أخرى بعد نشره في مجلة الجامعة ، كما لا يحق له طلب استرجاعه سواء قُبلَ للنشر أم لم يقبل .</li>
  <li>تخضع جميع الدراسات والبحوث والمقالات الواردة للمجلة على مُحكِمين مختصين تختارهم هيئة التحرير على نحو سري لتقدير مدى الصلاحية للنشر، وتقوم هيئة تحرير المجلة بإخطار الباحثين بآراء المحكمين ومقترحاتهم إذ كان المقال أو البحث في حال يسمح بالتعديل والتصحيح .</li>
  <li>لهيئة التحرير الحق في عدم نشر أي بحث أو دراسة تتعارض مع هذه الشروط دون إبداء الأسباب.</li>`,
  en: ``,
};

export const Gools1 = {
  ar: `أهداف المؤتمر`,
  en: `The objectives of the conference`,
  fr: `Les objectifs de la conférence`,
};

export const m = {
  ar: "محاور المؤتمر",
  en: "Conference axes",
  fr: "Axes de la conférence",
};

export const m1 = {
  ar: `المحور الأول: تأصيل مضمون الانتماء الوطني.`,
  en: ``,
  fr: ` `,
};
export const m1_1 = {
  ar: `<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>
 	القوانين والتشريعات.
</li>
<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>
 الموروث الثقافي.
</li>
`,
  en: ``,

  fr: ``,
};
export const m2 = {
  ar: " المحور الثاني: العملية التعليمية ودورها في تعزيز الانتماء الوطني.",
  en:
    "",
  fr: ` `,
};

export const m2_1 = {
  ar: `<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>
  دور المناهج التعليمية.
</li>
<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>
 	دور أعضاء هيئة التدريس.
</li>
`,
  en: ``,
  fr: ``,
};
export const m3 = {
  ar: ` المحور الثالث: الأنشطة الجامعية ودورها في تعزيز الانتماء الوطني.`,
 };
export const m3_1 = {
  ar: `<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>
 المنشورات والمؤتمرات والندوات وورش العمل.
</li>
<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>
  المسابقات والملتقيات.
<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>
  خدمة المجتمع والمصالحة الوطنية.
</li>`,
};

export const m4 = {
  ar: `  المحور الرابع: التجارب السابقة في مجال تعزيز الانتماء الوطني.`,

};
export const m4_1 = {
  ar: `<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>
	تجارب عربية.
</li>
<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>
  تجارب دولية.</li>
`,
  en: `<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>
  Developing the exploitation of marine water resources in transport, trade and marine products.
  </li>
<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>
  Shared digital free markets.</li>
<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>
  The open digital economy and its role in achieving sustainable development.
  </li>`,
  fr: `<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>Le développement de l'exploitation des ressources maritimes dans les transports, le commerce et les produits de mer.
</li>
<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>
  Les Marchés libres numériques partagés.</li>
<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>
  L'économie numérique ouverte et son rôle dans la réalisation du développement durable.
</li> `,
};

export const m5 = {
  ar: ` المحور الخامس / الإصلاحات التشريعية والمالية لخدمة القطاع
  الخاص`,
  en: ` Fifth Axis | Legislative and financial reforms to serve the private sector, including:`,
  fr: `Le cinquième axe / Réformes législatives et financières au service du secteur privé :`,
};

export const m5_1 = {
  ar: `<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>
  دعم الامتيازات والإعفاءات: ( الجمركية ، والضريبية ) من
  أجل خلق بيئة تُسهم في خلق مشروعات التنمية المستدامة.
</li>
<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>
  التشريعات والاصلاحات المؤسساتية: ( التسويقية،
  والمالية، والمصرفية، والنقدية ) ودورها في تحقيق
  التنمية المستدامة.
</li>
<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>
  دور المصارف الحكومية والتجارية والدولية والصناديق
  السيادية في تمويل برامج التنمية المستدامة.</li>`,
  en: `<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>
  Supporting concessions and exemptions: (customs and tax) in order to create an environment that contributes to creating sustainable development projects.
</li>
<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>
  Legislation and institutional reforms: (marketing, financial, banking, and monetary) and their role in achieving sustainable development.
</li>
<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>
  The role of governmental, commercial and international banks and sovereign funds in financing sustainable development programs
</li>`,
  fr: `<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>Soutenir les concessions et exonérations : (douanières et fiscales) afin de créer un environnement contribuant à la création de projets de développement durable.
</li>
<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>Législation et réformes institutionnelles : (commerciales, financières, bancaires et monétaires) et leur rôle dans la réalisation du développement durable.
</li>
<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>Le rôle des banques gouvernementales, commerciales et internationales et des fonds souverains dans le financement des programmes de développement durable
</li>`,
};

export const m6 = {
  ar: `  المحور السادس / إستثمار القطاع الخاص في الطاقات الخضراء
  والنظيفة`,
  en: `Sixth Axis | Private sector investment in green and clean energies, including:`,
  fr: `Le sixième axe / Investissement du secteur privé dans les énergies vertes et propres :`,
};

export const m6_1 = {
  ar: `<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>
  الفرص والتهديدات للاستثمار في الطاقات الخضراء: (شمسية،
  ومائية، وهوائية، وترابية ).
</li>
<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>
  سُبل تهيئة المناخ الاستثماري للقطاع الخاص في تبني
  مشروعات الطاقات الخضراء.
</li>
<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>
  تجارب دولية ونماذج أعمال لاستثمار القطاع الخاص في
  الطاقات الخضراء.
</li>`,
  en: `<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>	Opportunities and threats to invest in green energies: (solar, water, air, and dusty).
</li>
<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>	Ways to create an investment climate for the private sector in adopting green energy projects.
</li>
<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>International experiences and business models for private sector investment in green energies.
</li>`,
  fr: `<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>L’opportunités et les menaces d'investir dans les énergies vertes : (solaire, hydraulique, éolienne et terrestre).
</li>
<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>Les moyens de préparer un climat d'investissement pour le secteur privé dans l'adoption de projets d'énergie verte.
</li>
<li>
  <span
    class="icon icon-plus fa flaticon-tick"
    style={{ margin: "0 0.8rem" }}
  ></span>
  Les expériences internationales et les modèles commerciaux pour l'investissement du secteur privé dans les énergies vertes
</li>`,
};

export const Conferance = {
  ar: `دور الجامعات في تعزيز الانتماء الوطني`,
  en: `
  The first Mediterranean conference `,
  fr: `Le Premier Colloque Méditerranéen `,
};
export const HowToRegester = {
  ar: `شرح لطريقة المشاركة في المؤتمر`,
  en: `Explain how to participate in the conference`,
  fr: `Expliquez comment participer à la conférence`,
};

export const comission = {
  ar: `لجان الإشراف على المؤتمر ...`,
  en: `Supervisory committees`,
  fr: `Comités de surveillance`,
};

export const allPapers = {
  ar: "إجمالي الورقات المستلمة ",
  en: "Total papers received",
  fr: "Total des articles reçus",
};
export const acceptedPapers = {
  ar: "عدد الورقات المقبولة ",
  en: "Total researchs accepted",
  fr: "Nombre d'articles acceptés",
};

export const rejectedPapers = {
  ar: "عدد الورقات المرفوضة ",
  en: "Total Researchs Rejected",
  fr: "Nombre d'articles acceptés",
};

export const s = {
  ar: `رعاة المؤتمر `,
  en: `Sponsors`,
  fr: `Les sponsors`,
};

export const Date1 = {
  ar: `بداية استلام الملخصات`,
  en: `Receipt of research papers to  15-06-2021`,
  fr: `Receipt of research papers to  15-06-2021`,
};
export const Date2 = {
  ar: `آخر موعد لاستلام الملخصات`,
  en: `Announcement of accepted papers`,
  fr: `Announcement of accepted papers`,
};
export const Date3 = {
  ar: `إشعار الباحث بنتيجة التقييم`,
  en: `Opening of the conference`,
  fr: `Opening of the conference`,
};
export const Date4 = {
  ar: `آخر موعد لاستلام الورقات البحثية`,
  en: `Scientific conference sessions and projects
  `,
  fr: `Scientific conference sessions and projects
  `,
};
export const Date5 = {
  ar: `آخر موعد لاستلام الورقات البحثية بالتعديلات النهائية`,
  en: `Scientific sessions and projects
  `,
  fr: `Scientific sessions and projects`,
};
export const Date6 = {
  ar: `إشعار الباحث بالقبول النهائي`,
  en: `Mediterranean Youth Entrepreneur Forum
& Investment opportunities in the agricultural, marine and transportation sectors.
  `,
  fr: `Mediterranean Youth Entrepreneur Forum &
  Investment opportunities in the agricultural, marine and transportation sectors.
  `,
};
export const Date7 = {
  ar: `إفتتاح المؤتمر وانعقاد الجلسات`,
  en: `Tourist trip  `,
  fr: `Tourist trip  `,
};
export const Date8 = {
  ar: `إختتام المؤتمر`,
  en: `Conclusion of the conference.`,
  fr: `Conclusion of the conference.`,
};

export const c1 = {
  ar: `المنسقون...`,
  en: `Coordinators`,
  fr: `coordinateurs`,
};

export const c2 = {
  ar: `
  
  <div class="col m6">
      <ol>
      <li>د. حسن الشيباني - ليبيا  <i
      class="material-icons teal-text"
      style={{ marginLeft: "15px" }}
    >
    phone
    </i> 00218913672429 </li>
    <li>
    د. مرصالي حورية - الجزائر <i
     class="material-icons teal-text"
     style={{ marginLeft: "15px" }}
   >
   phone
   </i>
   00213798481647
   </li>
   <li> د. منير الكبير - ماليزيا  <i
   class="material-icons teal-text"
   style={{ marginLeft: "15px" }}
 >
 phone
 </i> 00601137011603</li>
      </ol>
      <h6>لمزيدا من المعلومات الرجاء مراسلتنا على البريد الالكتروني التالي: FIMSD2021@zu.edu.ly</h6>
    </div>
`,
  en: `<div class="col m6">
   <ol>
    <li>Hassan Alshibany - Libya<i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
  phone
  </i> 00218913672429 </li>
  <li>
  Morsali Houri - Algeria<i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
  phone
  </i>
  00213798481647
  </li>
  <li>Munther Al-Kabeer - Malaysia  <i
  class="material-icons teal-text"
  style={{ marginLeft: "15px" }}
>
phone
</i> 00601137011603</li>
</ol>
<h5>If you have any questions,Please email us at  FIMSD2021@zu.edu.ly</h5>
</div>`,

  fr: `<div class="col m6">
<ol>
 <li>Hassan Alshibany - Libya<i
 class="material-icons teal-text"
 style={{ marginLeft: "15px" }}
>
phone
</i> 00218913672429 </li>
<li>
Morsali Houri - Algeria<i
 class="material-icons teal-text"
 style={{ marginLeft: "15px" }}
>
phone
</i>
00213798481647
</li>
<li>Munther Al-Kabeer - Malaysia  <i
class="material-icons teal-text"
style={{ marginLeft: "15px" }}
>
phone
</i> 00601137011603</li>
</ol>
<h5>Si vous avez des questions, veuillez nous envoyer un courriel à FIMSD2021@zu.edu.ly</h5>
</div>`,
};

export const D1 = {
  ar: `مواعيد مهمة`,
  en: `Important Dates`,
  fr: `Rendez-vous importants`,
};
export const D2 = {
  ar: `<ol> <li>
  <i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
    check
  </i>
  الإعلان عن المؤتمر:15-9-2020
</li>
<li>
  <i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
    check
  </i>
  قبول الورقات العلمية: من 01-10-2020 الى  15-12-2020 
   </li>

<li>
  <i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
    check
  </i>
  موعد انعقاد المؤتمر: من 12-03-2021 الى 16-03-2021
   </li>

<li>
  <i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
    check
  </i>
   البرنامج التدريبي المصاحب: 15-03-2021
</li>
<li>
  <i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
    check
  </i>
  الرحلة السياحية: 16-03-2021
    </li>
  <li>
  <span class=" red-text"> ملاحظات:</span>   </br>
   في حال تعذر المشارك من الحضور لمقر انعقاد المؤتمر يمكنه عرض الورقة الكترونياً عبر الفضاء الالكتروني.</br>
  المشاركة والنشر مجاناً .</br>
  الاعاشة والاقامة مجانية في مقر انعقاد المؤتمر بتونس.</br>
  لايتحمل المسؤولين عن المؤتمر تكاليف النقل والسفر.
</li>
</ol>
`,

  en: `<ol> <li>
  <i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
    check
  </i>
  Conference announcement: 15-September-2020
</li>
<li>
  <i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
    check
  </i>
  Paper Submission due: 01-October-2020 to 15-December-2020 
   </li>

<li>
  <i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
    check
  </i>
  Conference Date due: 12-Mar-2021 to 16-Mar-2021
</li>
<li>
  <i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
    check
  </i>
  Training day: 15-Mar-2021
 </li>
<li>
  <i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
    check
  </i>
  Tourist trip: 16-Mar-2021
  </li>
</ol>
<h5>Notes:</h5>   
<ol>
<li>  
<i
  class="material-icons teal-text"
  style={{ marginLeft: "15px" }}
>
  check
</i>
If the participant is unable to attend the conference venue, he can view the paper electronically via the electronic space.
</li>
<li>  
<i
  class="material-icons teal-text"
  style={{ marginLeft: "15px" }}
>
  check
</i>
Participation and publication in the conference is free of charge.
</li><li>  
<i
  class="material-icons teal-text"
  style={{ marginLeft: "15px" }}
>
  check
</i>
Free accommodation and accommodation at the conference venue in Tunis.
</li>
<li>  
<i
  class="material-icons teal-text"
  style={{ marginLeft: "15px" }}
>
  check
</i>
The conference are not responsible for transportation and travel costs.
</li>
</ol>
`,
  fr: `<ol> <li>
<i
  class="material-icons teal-text"
  style={{ marginLeft: "15px" }}
>
  check
</i>
Conference announcement: 15-September-2020
</li>
<li>
<i
  class="material-icons teal-text"
  style={{ marginLeft: "15px" }}
>
  check
</i>
Paper Submission due: 01-Octobre-2020 to 15-December-2020 
 </li>

<li>
<i
  class="material-icons teal-text"
  style={{ marginLeft: "15px" }}
>
  check
</i>
Conference Date due: 12-mars-2021 to 16-mars-2021
</li>
<li>
<i
  class="material-icons teal-text"
  style={{ marginLeft: "15px" }}
>
  check
</i>
Training day: 15-mars-2021
</li>
<li>
<i
  class="material-icons teal-text"
  style={{ marginLeft: "15px" }}
>
  check
</i>
Tourist trip: 16-mars-2021
</li>
</ol>
<h5>Remarques:</h5>   
<ol>
<li>  
<i
class="material-icons teal-text"
style={{ marginLeft: "15px" }}
>
check
</i>
Si le participant n'est pas en mesure de se rendre sur le lieu de la conférence, il peut consulter le papier par voie électronique via l'espace électronique.
</li>
<li>  
<i
class="material-icons teal-text"
style={{ marginLeft: "15px" }}
>
check
</i>
La participation et la publication à la conférence sont gratuites.
</li><li>  
<i
class="material-icons teal-text"
style={{ marginLeft: "15px" }}
>
check
</i>
Hébergement et hébergement gratuits sur le lieu de la conférence à Tunis.</li>
<li>  
<i
class="material-icons teal-text"
style={{ marginLeft: "15px" }}
>
check
</i>
La conférence n'est pas responsable des frais de transport et de déplacement.
</li>
</ol>
`,
};

export const footer = {
  ar: ` الموقع الالكتروني - جامعة الزاوية`,
  en: `Website Department-Unversity of Zawia`,
  fr: `Website Department-Unversity of Zawia`,
};

export const Close = {
  ar: `اغلاق`,
  en: `Close`,
  fr: `Fermer`,
};

export const Bosses = [
  {
    image: "./img/team/team1.jpg",
    type: "none",
    name: "أ.سامي سعيد شلادي",
    capacity: "مشرف عام المؤتمر",
    capacity1: "وكيل عام مجلس التخطيط الوطني الليبي ",
  },
  {
    image: "./img/team/team2.jpg",
    name: "أ.د.عدالة العجال",
    type: "scientific",
    capacity: "رئيس اللجنة العلمية",
    committe: "العلمية",
  },
  {
    image: "./img/team/team3.jpg",
    name: "د.ياسين ابوسريويل",
    type: "preparatory",
    capacity: "رئيس اللجنة التحضيرية",
    committe: "التحضيرية",
  },
  {
    image: "./img/team/team4.jpg",
    type: "none",
    name: "د. الصديق خليفة الكيلاني ",
    capacity: "منسق للمؤتمر",
  },
];

export const CommiteeMembers = [
  {
    image: "",
    sex: "ذكر",
    name: "أ.حامد حسين بن كورة",
    capacity: "عضو اللجنة التقنية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "أ.احمد ابراهيم سليمان",
    capacity: "عضو اللجنة التقنية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د.حسن مولودالشيباني",
    capacity: "عضو اللجنة التقنية",
  },
  {
    image: "",
    sex: "انثى",
    type: "preparatory",
    name: "أ.أسماء البشير الشائبي",
    capacity: "رئيس لجنة التواصل",
  },
  {
    image: "",
    sex: "انثى",
    name: "أ.فطيمة الشيباني مسعود",
    capacity: "عضو اللجنة التقنية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "حمزة موسي الدوكالي ",
    capacity: "عضو اللجنة التقنية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "م.إلياس عبدالله بالحاج",
    capacity: "عضو اللجنة التقنية",
  },
  ///////////////////////////////////////////////*اللجنة الاعلامية*//////////////////////////////////////////////////////
  {
    image: "",
    sex: "ذكر",
    name: "أحمد سعيد السنوسي",
    capacity: "رئيس الجنة الاعلامية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "المقداد سالم نصرات",
    capacity: "عضو اللجنة الاعلامية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "محمد سالم ابوالقاسم",
    capacity: "عضو اللجنة الاعلامية",
  },
  {
    image: "",
    sex: "انثى",
    name: "عواطف البشير البشتي",
    capacity: "عضو اللجنة الاعلامية",
  },
  {
    image: "",
    sex: "انثى",
    name: "ميساء شيماء كريبازة",
    capacity: "عضو اللجنة الاعلامية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "عبدالمجيد محمد ابوشعالة",
    capacity: "عضو اللجنة الاعلامية",
  },
];
export const OtherComitte = [
  ///////////////////////////////////////////////*اللجنة التحضيرية*//////////////////////////////////////////////////////

  {
    image: "",
    sex: "ذكر",
    name: "د.عبدالسميع عامر معمر",
    type: "preparatory",
    capacity: "عضو اللجنة التحضيرية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د.حمزة الهادي كشلاف",
    type: "preparatory",
    capacity: "عضو اللجنة التحضيرية",
  },
  {
    image: "",
    sex: "انثى",
    name: "د. الزهرة الشيخ عبدالله",
    type: "preparatory",
    capacity: "عضو اللجنة التحضيرية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د.حسن على موتات",
    type: "preparatory",
    capacity: "عضو اللجنة التحضيرية",
  },
  {
    image: "",
    sex: "انثى",
    name: "أ.خولة بنت عبدالله التريكي",
    type: "preparatory",
    capacity: "عضو اللجنة التحضيرية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د. محمد الزطريني",
    type: "preparatory",
    capacity: "عضو اللجنة التحضيرية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "مصباح سالم الكانوني",
    type: "preparatory",
    capacity: "عضو اللجنة التحضيرية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "طارق عبدالرزاق الطشاني",
    type: "preparatory",
    capacity: "عضو اللجنة التحضيرية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "محمد خليفة المرزوقي",
    type: "preparatory",
    capacity: "عضو اللجنة التحضيرية",
  },
  {
    image: "",
    sex: "انثى",
    name: "أ.مروة محمد الزروق",
    type: "preparatory",
    capacity: "عضو اللجنة التحضيرية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د. عبدالله عبدالرحيم",
    type: "preparatory",
    capacity: "عضو اللجنة التحضيرية",
  },
  //////////////////////////////////////////////*لجنة التواصل*//////////////////////////////////////////////////////
  {
    image: "",
    sex: "انثى",
    type: "preparatory",
    name: "أ.أسماء البشير الشائبي",
    capacity: "رئيس لجنة التواصل",
  },
  {
    image: "",
    sex: "انثى",
    type: "preparatory",
    name: "احلام عبدالناصر بن طابون",
    capacity: "عضو لجنة التواصل",
  },
  {
    image: "",
    sex: "انثى",
    type: "preparatory",
    name: "وداد علي ابو شاقور",
    capacity: "عضو لجنة التواصل",
  },
  {
    image: "",
    sex: "انثى",
    type: "preparatory",
    name: "أ.نور عبدالله شرلالة",
    capacity: "عضو لجنة التواصل",
  },
  {
    image: "",
    sex: "انثى",
    type: "preparatory",
    name: "أماني ابراهيم الاربش",
    capacity: "عضو لجنة التواصل",
  },
  {
    image: "",
    sex: "انثى",
    type: "preparatory",
    name: "أ.اسماء حسين  العقيلي",
    capacity: "عضو لجنة التواصل",
  },
  {
    image: "",
    sex: "انثى",
    type: "preparatory",
    name: "أ.مارن خالد التائب",
    capacity: "عضو لجنة التواصل",
  },
  {
    image: "",
    sex: "ذكر",
    type: "preparatory",
    name: "أ.محمد سالم بلوط",
    capacity: "عضو لجنة التواصل",
  },
  ///////////////////////////////////////////////*اللجنة العلمية*//////////////////////////////////////////////////////

  {
    image: "",
    sex: "ذكر",
    name: "أ.د.فلة محمد",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },

  {
    image: "",
    sex: "ذكر",
    name: "أ.د.باركة محمد الزين",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د.باسم كاظم خلف",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د.منير فرج الكبير",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د.الجيلي محجوب أحمد",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د.خلاصي عبد الإله",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د.محمود أحمد فحيل البوم",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د.جليل زين العابدين",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د.نوري فلو  ",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "انثى",
    name: "د.فداء إبراهيم المصري",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "انثى",
    name: "د. بثينة الغلبزوري",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "انثى",
    name: "د.عياد ليلى",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د.ميلود الرحالي",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "انثى",
    name: "د.مرصالي حورية",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د.رجب عبد السلام العموري",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د.وادي عز الدين",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د.تراكة جمال",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د.محمد الشيباني أبو شعفة",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د.ياسر الكرماني ",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د.عبد السلام محمد بلقاسم",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د.عبد الله رمضان بنيني",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د.سلاف السماوي",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
];

/////////////////////////////////////////////////////////الانجليزية/////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////اللجنة التقنية EN////////////////////////////////////////////
export const CommiteeMembersEN = [
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Hamid Hussein bin Kora",
    capacity: "Member of the Technical Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Ahmed Ibrahim Suleiman",
    capacity: "Member of the Technical Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Hassan Mouloud Al-Shaibani",
    capacity: "Member of the Technical Committee",
  },
  {
    image: "",
    sex: "انثى",
    name: "Mrs.Fatima Al-Shaibani Masoud",
    capacity: "Member of the Technical Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Hamza Moussa Al-Doukali ",
    capacity: "Member of the Technical Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Elias Abdullah Belhaj",
    capacity: "Member of the Technical Committee",
  },
  ///////////////////////////////////////////////*ENاللجنة الاعلامية*//////////////////////////////////////////////////////
  {
    image: "",
    sex: "ذكر",
    name: "Ahmed Saeed Al-Senussi",
    capacity: "Head of the Media Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Miqdad Salem Nusrat",
    capacity: "Member of the Media Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mohammed Salem Abu Al-Qasim",
    capacity: "Member of the Media Committee",
  },
  {
    image: "",
    sex: "انثى",
    name: "Ewatif Al-bashir Al-bshty",
    capacity: "Member of the Media Committee",
  },
  {
    image: "",
    sex: "انثى",
    name: "Maysa Shaimaa Kribaza",
    capacity: "Member of the Media Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Abdul Majeed Mohammed Abu Shaala",
    capacity: "Member of the Media Committee",
  },
];
export const OtherComitteEN = [
  //////////////////////////////////////////////*ENلجنة التواصل*//////////////////////////////////////////////////////
  {
    image: "",
    sex: "انثى",
    name: "Mrs.Asma Al-Bashir Al-Shaibi",
    capacity: "Head of the Communication Committee",
  },
  {
    image: "",
    sex: "انثى",
    name: "Mrs.Ahlam Nasser bin Tabon",
    capacity: "Member of the Communication Committee",
  },
  {
    image: "",
    sex: "انثى",
    name: "Mrs.Wedad Ali Abu Shaqour",
    capacity: "Member of the Communication Committee",
  },
  {
    image: "",
    sex: "انثى",
    name: "Mrs.Nour Abdullah Sharlala",
    capacity: "Member of the Communication Committee",
  },
  {
    image: "",
    sex: "انثى",
    name: "Mrs.Amani Ibrahim Al-Arbash",
    capacity: "Member of the Communication Committee",
  },
  {
    image: "",
    sex: "انثى",
    name: "Mrs.Asma Hussein Al-Aqili",
    capacity: "Member of the Communication Committee",
  },
  {
    image: "",
    sex: "انثى",
    name: "Mrs.Marne Khalid Taib",
    capacity: "Member of the Communication Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Mohammed Salem Ballout",
    capacity: "Member of the Communication Committee",
  },
  ///////////////////////////////////////////////*اللجنة التحضيرية*//////////////////////////////////////////////////////

  {
    image: "",
    sex: "ذكر",
    name: "Dr. Abdul Sami Amer Muammar",
    type: "preparatory",
    capacity: "Member of the Preparatory Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Hamza Al-Hadi Kashlaf",
    type: "preparatory",
    capacity: "Member of the Preparatory Committee",
  },
  {
    image: "",
    sex: "انثى",
    name: "Dr.Al-Zahra Sheikh Abdullah",
    type: "preparatory",
    capacity: "Member of the Preparatory Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Hassan Ali Mutat",
    type: "preparatory",
    capacity: "Member of the Preparatory Committee",
  },
  {
    image: "",
    sex: "انثى",
    name: "Mrs.Khawla bint Abdullah Al-Triki",
    type: "preparatory",
    capacity: "Member of the Preparatory Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr. Muhammad Al-Zatarini",
    type: "preparatory",
    capacity: "Member of the Preparatory Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mesbah Salem Al Kanouni",
    type: "preparatory",
    capacity: "Member of the Preparatory Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Tariq Abdul Razzaq Al-Tashani",
    type: "preparatory",
    capacity: "Member of the Preparatory Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mohammed Khalifa Al Marzouki",
    type: "preparatory",
    capacity: "Member of the Preparatory Committee",
  },
  {
    image: "",
    sex: "انثى",
    name: "Marwa Mohammed Al-Zarrouk",
    type: "preparatory",
    capacity: "Member of the Preparatory Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr. Abdullah Abdul Rahim",
    type: "preparatory",
    capacity: "Member of the Preparatory Committee",
  },
  ///////////////////////////////////////////////*ENاللجنة العلمية*//////////////////////////////////////////////////////

  {
    image: "",
    sex: "ذكر",
    name: "Dr.Fella Muhammad",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Barkaa Muhammad Al-Zein",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Basem Kazem Khalaf",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Mounir Farag Al Kabeer",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Al-Jelly Mahjoub Ahmed",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Khalasi Abdul Ilah",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Mahmoud Ahmed Fahail album",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Jalil Zine El Abidine",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "انثى",
    name: "Dr.Fida Ibrahim al-Masry",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "انثى",
    name: "Dr.Buthaina Al-Ghalbzouri",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "انثى",
    name: "Dr.Ayyad Layla",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Miloud El Rahali",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "انثى",
    name: "Dr.Marsali Hawria",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Ragab Abdel Salam Al-Amouri",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Wadi Izz al-Din",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Trakha Jamal",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Mohammed Al-Shaibani Abu Shaafa",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Yasser Al-Kirmani ",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Abdel Salam Mohamed Belkacem",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Abdullah Ramadan Benini",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Slaf Alsamawii",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
];

//////////////////////////////////////////////////////الفرنسية/////////////////////////////////////////////////
export const BossesFR = [
  {
    image: "./img/team/team1.jpg",
    type: "none",
    name: "Mr.Sami Saeed Shaladi",
    capacity: "superviseur général",
    capacity1: "Général adjoint du Conseil national de planification libyen ",
  },
  {
    image: "./img/team/team2.jpg",
    name: "Prof. Aadalh Al-Ajal ",
    type: "scientific",
    capacity: "Chef du comité scientifique",
  },
  {
    image: "./img/team/team3.jpg",
    name: "Dr.Yassin Abusriwel",
    type: "preparatory",
    capacity: "Président du Comité préparatoire",
  },
  {
    image: "./img/team/team4.jpg",
    type: "none",
    name: "Dr.Al-Siddiq Khalifa Al-Kilani ",
    capacity: "Coordinateur de conférence",
  },
];

/////////////////////////////////////////////////////////اللجنة التقنية FR/////////////////////////////////////
export const CommiteeMembersFR = [
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Hamid Hussein bin Kora",
    capacity: "Membre du comité technique",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Ahmed Ibrahim Suleiman",
    capacity: "Membre du comité technique",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Hassan Mouloud Al-Shaibani",
    capacity: "Membre du comité technique",
  },
  {
    image: "",
    sex: "انثى",
    name: "Mrs.Asma Al Shaibi",
    capacity: "",
  },
  {
    image: "",
    sex: "انثى",
    name: "Mrs.Fatima Al-Shaibani Masoud",
    capacity: "Membre du comité technique",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Hamza Moussa Al-Doukali ",
    capacity: "Membre du comité technique",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Elias Abdullah Belhaj",
    capacity: "Membre du comité technique",
  },
  ///////////////////////////////////////////////*FRاللجنة الاعلامية*//////////////////////////////////////////////////////
  {
    image: "",
    sex: "ذكر",
    name: "Ahmed Saeed Al-Senussi",
    capacity: "Chef du comité des médias",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Miqdad Salem Nusrat",
    capacity: "Membre du comité des médias",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mohammed Salem Abu Al-Qasim",
    capacity: "Membre du comité des médias",
  },
  {
    image: "",
    sex: "انثى",
    name: "Ewatif Al-bashir Al-bshty",
    capacity: "Membre du comité des médias",
  },
  {
    image: "",
    sex: "انثى",
    name: "Maysa Shaimaa Kribaza",
    capacity: "Membre du comité des médias",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Abdul Majeed Mohammed Abu Shaala",
    capacity: "Membre du comité des médias",
  },
];
export const OtherComitteFR = [
  //////////////////////////////////////////////*FRلجنة التواصل*//////////////////////////////////////////////////////
  {
    image: "",
    sex: "انثى",
    name: "Mrs.Asma Al-Bashir Al-Shaibi",
    capacity: "Chef du comité de communication",
  },
  {
    image: "",
    sex: "انثى",
    name: "Mrs.Ahlam Nasser bin Tabon",
    capacity: "Membre du comité de communication",
  },
  {
    image: "",
    sex: "انثى",
    name: "Mrs.Wedad Ali Abu Shaqour",
    capacity: "Membre du comité de communication",
  },
  {
    image: "",
    sex: "انثى",
    name: "Mrs.Nour Abdullah Sharlala",
    capacity: "Membre du comité de communication",
  },
  {
    image: "",
    sex: "انثى",
    name: "Mrs.Amani Ibrahim Al-Arbash",
    capacity: "Membre du comité de communication",
  },
  {
    image: "",
    sex: "انثى",
    name: "Mrs.Asma Hussein Al-Aqili",
    capacity: "Membre du comité de communication",
  },
  {
    image: "",
    sex: "انثى",
    name: "Mrs.Marne Khalid Taib",
    capacity: "Membre du comité de communication",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Mohammed Salem Ballout",
    capacity: "Membre du comité de communication",
  },
  ///////////////////////////////////////////////*FRاللجنة التحضيرية*//////////////////////////////////////////////////////

  {
    image: "",
    sex: "ذكر",
    name: "Dr. Abdul Sami Amer Muammar",
    type: "preparatory",
    capacity: "Membre du comité préparatoire",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Hamza Al-Hadi Kashlaf",
    type: "preparatory",
    capacity: "Membre du comité préparatoire",
  },
  {
    image: "",
    sex: "انثى",
    name: "Dr.Al-Zahra Sheikh Abdullah",
    type: "preparatory",
    capacity: "Membre du comité préparatoire",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Hassan Ali Mutat",
    type: "preparatory",
    capacity: "Membre du comité préparatoire",
  },
  {
    image: "",
    sex: "انثى",
    name: "Mrs.Khawla bint Abdullah Al-Triki",
    type: "preparatory",
    capacity: "Membre du comité préparatoire",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr. Muhammad Al-Zatarini",
    type: "preparatory",
    capacity: "Membre du comité préparatoire",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mesbah Salem Al Kanouni",
    type: "preparatory",
    capacity: "Membre du comité préparatoire",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Tariq Abdul Razzaq Al-Tashani",
    type: "preparatory",
    capacity: "Membre du comité préparatoire",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mohammed Khalifa Al Marzouki",
    type: "preparatory",
    capacity: "Membre du comité préparatoire",
  },
  {
    image: "",
    sex: "انثى",
    name: "Marwa Mohammed Al-Zarrouk",
    type: "preparatory",
    capacity: "Membre du comité préparatoire",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr. Abdullah Abdul Rahim",
    type: "preparatory",
    capacity: "Membre du comité préparatoire",
  },
  ///////////////////////////////////////////////*FRاللجنة العلمية*//////////////////////////////////////////////////////

  {
    image: "",
    sex: "ذكر",
    name: "Dr.Fella Muhammad",
    type: "scientific",
    capacity: "Membre du comité scientifique",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Barkaa Muhammad Al-Zein",
    type: "scientific",
    capacity: "Membre du comité scientifique",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Basem Kazem Khalaf",
    type: "scientific",
    capacity: "Membre du comité scientifique",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Mounir Farag Al Kabeer",
    type: "scientific",
    capacity: "Membre du comité scientifique",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Al-Jelly Mahjoub Ahmed",
    type: "scientific",
    capacity: "Membre du comité scientifique",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Khalasi Abdul Ilah",
    type: "scientific",
    capacity: "Membre du comité scientifique",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Mahmoud Ahmed Fahail album",
    type: "scientific",
    capacity: "Membre du comité scientifique",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Jalil Zine El Abidine",
    type: "scientific",
    capacity: "Membre du comité scientifique",
  },
  {
    image: "",
    sex: "انثى",
    name: "Dr.Fida Ibrahim al-Masry",
    type: "scientific",
    capacity: "Membre du comité scientifique",
  },
  {
    image: "",
    sex: "انثى",
    name: "Dr.Buthaina Al-Ghalbzouri",
    type: "scientific",
    capacity: "Membre du comité scientifique",
  },
  {
    image: "",
    sex: "انثى",
    name: "Dr.Ayyad Layla",
    type: "scientific",
    capacity: "Membre du comité scientifique",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Miloud El Rahali",
    type: "scientific",
    capacity: "Membre du comité scientifique",
  },
  {
    image: "",
    sex: "انثى",
    name: "Dr.Marsali Hawria",
    type: "scientific",
    capacity: "Membre du comité scientifique",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Ragab Abdel Salam Al-Amouri",
    type: "scientific",
    capacity: "Membre du comité scientifique",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Wadi Izz al-Din",
    type: "scientific",
    capacity: "Membre du comité scientifique",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Trakha Jamal",
    type: "scientific",
    capacity: "Membre du comité scientifique",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Mohammed Al-Shaibani Abu Shaafa",
    type: "scientific",
    capacity: "Membre du comité scientifique",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Yasser Al-Kirmani ",
    type: "scientific",
    capacity: "Membre du comité scientifique",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Abdel Salam Mohamed Belkacem",
    type: "scientific",
    capacity: "Membre du comité scientifique",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Abdullah Ramadan Benini",
    type: "scientific",
    capacity: "Membre du comité scientifique",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Slaf Alsamawii",
    type: "scientific",
    capacity: "Membre du comité scientifique",
  },
];

export const vedname = {
  en:
    "Une vidéo explicative en français expliquant les objectifs et les thèmes de la conférence",
  ar: "فيديو  يوضح أهداف ومحاور المؤتمر. ",
  fr:
    "Une vidéo explicative en français expliquant les objectifs et les thèmes de la conférence",
};

export const btnTitle = {
  en: "How to Register",
  ar: "كيفية التسجيل",
  fr: "Comment s'inscrire",
};
export const btnTitleG = {
  en: "Thèmes de la conférence",
  ar: "شرح محاور المؤتمر",
  fr: "Thèmes de la conférence",
};

export const CountDown = {
  ar: "العد التنازلي لقبول الاوراق البحثية",
  en: "Countdown to the acceptance of the research papers",
  fr: "Compte à rebours pour l'acceptation des articles de recherche",
};
