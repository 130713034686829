import React from "react";
import { useSelector } from "react-redux";
import { s, navlink2 } from "../common/sections";
const Sponsor = () => {
  const { lang } = useSelector((state) => state.research);
  return (
    <section
      id="sponsors"
      className="clients-section"
      style={{
        direction: lang !== "ar" && "ltr",
        textAlign: lang !== "ar" && "left",
      }}
    >
      <div className="anim-icons">
        <span className="icon icon-dots-3 wow zoomIn"></span>
        <span className="icon icon-circle-blue wow zoomIn"></span>
      </div>
      <div className="auto-container">
        <div className="sec-title">
          <span className="newtitle text-center"> {navlink2[lang]}</span>
          <h2> </h2>
        </div>

        <div className="sponsors-outer">
          {/* <h3>{navlink2[lang]}</h3> */}
          <div className="row">
            {/* <!-- Client Block --> */}
            <div className="client-block col-lg-6 col-md-6 col-sm-12">
              <figure className="image-box">
                <a href="http://rcc.zu.edu.ly/">
                  <img src="images/rcc.png" alt="" />
                </a>
              </figure>
            </div>

            {/* <!-- Client Block --> */}
            <div className="client-block col-lg-6 col-md-6 col-sm-12">
              <figure className="image-box">
                <a href="http://zu.edu.ly/faculty/5d91e2ac8a05653bb5d8a76c">
                  <img src="images/fol.png" height="100" alt="" />
                </a>
              </figure>
            </div>

           
          </div>
        </div>

        <div className="sponsors-outer">
          {/* <h3>{s[lang]}</h3> */}

          <div className="row">
            {/* <!-- Client Block --> */}
            {/* <div className="client-block col-lg-3 col-md-6 col-sm-12">
              <figure className="image-box">
                <a href="#">
                  <img src="images/gov.png" alt="" />
                </a>
              </figure>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Sponsor;
