import React from "react";
import { useSelector } from "react-redux";
import { Gools1 } from "../common/sections";
const Hub = () => {
  const { lang } = useSelector((state) => state.research);
  return (
    <section id="goals" className="features-section-three no-pd-top">
      <div className="auto-container">
        <div
          className="content-box sec-title"
          style={{
            textAlign: "center",
          }}
        >
          <h1
            className="title"
            style={{ fontSize: lang === "ar" ? "3rem" : "2rem" }}
          >
            {Gools1[lang]}
          </h1>
        </div>

        <div
          className="row"
          style={{
            direction: lang !== "ar" && "ltr",
            textAlign: lang !== "ar" && "ltr",
          }}
        >
          <div className="feature-block-three col-lg-6 col-md-12 col-sm-12 wow fadeInUp">
            <div className="inner-box">
              <div className="icon-box">
                <span className="icon flaticon-group-2"></span>
              </div>
              <div
                className="text text-justify"
                style={{ fontSize: lang === "ar" ? "1.3rem" : "1.1rem" }}
              >
                {lang === "ar" && " 1.إلقاء الضوء على التأصيل القانوني لمضمون الانتماء الوطني."}
                </div>
              <div className="link-box">
                <a href="about.html" className="theme-btn">
                  <span className="fa fa-angle-right"></span>
                </a>
              </div>
            </div>
          </div>
          <div className="feature-block-three col-lg-6 col-md-12 col-sm-12 wow fadeInUp">
            <div className="inner-box">
              <div className="icon-box">
                <span
                  className="icon flaticon-graph-1
"
                ></span>
              </div>
              <div
                className="text text-justify"
                style={{ fontSize: lang === "ar" ? "1.2rem" : "1.1rem" }}
              >
                {lang === "ar" &&
                  "2. إظهار دور الموروث الثقافي في تعزيز الانتماء الوطني."}
               
              </div>
              <div className="link-box">
                <a href="about.html" className="theme-btn">
                  <span className="fa fa-angle-right"></span>
                </a>
              </div>
            </div>
          </div>
          <div className="feature-block-three col-lg-6 col-md-12 col-sm-12 wow fadeInUp">
            <div className="inner-box">
              <div className="icon-box">
                <span className="icon flaticon-desktop-computer-with-magnifying-lens-focusing-on-data"></span>
              </div>
              <div
                className="text text-justify"
                style={{ fontSize: lang === "ar" ? "1.1rem" : "1.1rem" }}
              >
                {lang === "ar" &&
                  `3.التعرف على دور العملية التعليمية في ترسيخ الانتماء الوطني.`}
               
              </div>
              <div className="link-box">
                <a href="about.html" className="theme-btn">
                  <span className="fa fa-angle-right"></span>
                </a>
              </div>
            </div>
          </div>
          <div className="feature-block-three col-lg-6 col-md-12 col-sm-12 wow fadeInUp">
            <div className="inner-box">
              <div className="icon-box">
                <span className="icon flaticon-success"></span>
              </div>
              <div
                className="text text-justify"
                style={{ fontSize: lang === "ar" ? "1.2rem" : "1.1rem" }}
              >
                {lang === "ar" &&
                  " 4.إبارز دور الأنشطة الجامعية في تعزيز الانتماء الوطني."}
                
              </div>
              <div className="link-box">
                <a href="about.html" className="theme-btn">
                  <span className="fa fa-angle-right"></span>
                </a>
              </div>
            </div>
          </div>
          <div className="feature-block-three col-lg-6 col-md-12 col-sm-12 wow fadeInUp">
            <div className="inner-box">
              <div className="icon-box">
                <span className="icon flaticon-stocks-graphic-on-laptop-monitor"></span>
              </div>
              <div
                className="text text-justify"
                style={{ fontSize: lang === "ar" ? "1.1rem" : "1.1rem" }}
              >
                {lang === "ar" &&
                  `5.عرض التجارب الناجحة في مجال تعزيز قيم الانتماء الوطني.`}
               
              </div>
              <div className="link-box">
                <a href="about.html" className="theme-btn">
                  <span className="fa fa-angle-right"></span>
                </a>
              </div>
            </div>
          </div>
          <div className="feature-block-three col-lg-6 col-md-12 col-sm-12 wow fadeInUp">
            <div className="inner-box">
              <div className="icon-box">
                <span className="icon flaticon-team"></span>
              </div>
              <div
                className="text text-justify"
                style={{ fontSize: lang === "ar" ? "1.1rem" : "1.1rem" }}
              >
                {lang === "ar" &&
                  `6.تحديد الصعوبات التي تعترض الجامعات في ترسيخ قيم الانتماء الوطني.`}
               
              </div>
              <div className="link-box">
                <a href="about.html" className="theme-btn">
                  <span className="fa fa-angle-right"></span>
                </a>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </section>
  );
};

export default Hub;
